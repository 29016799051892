import { Link } from 'react-router-dom';

function Menu(params) {
  return (
    <div>
      {
        window._my_menu.length>0 ?
          <div className="menu_calavera">
            {
              window._my_menu.map((item, i) =>
                <Link to={item} key={i} onClick={() => {
                  if(params.setVerMenu){
                    params.setVerMenu('no_ver_menu')
                  }
                }}>{item}</Link>
              )
            }
          </div>
        :
          null
      }
    </div>
  );
}

export default Menu;

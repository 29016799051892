import { useEffect } from 'react';
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Historia() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'HISTORIA - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div className="max_width padding_width_chico blanco">
      <div className="flex_historia">
        <img src="img/historia.jpg" alt="Historia" className="img_historia" data-aos="fade-right"/>
        <div data-aos="fade-left">
          <h11>HISTORIA</h11>
          <br/><br/>
          Somos un estudio de tatuajes fundado hace más de 25 años en los que hemos tenido un gran maestro que dejó todo su conocimiento y destreza en el tatuaje.
          Profesionalismo y sencillez es nuestra carta de presentación.
        </div>
      </div>
    </div>
  );
}

export default Historia;

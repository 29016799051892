import { useEffect } from 'react';
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Tatuaje() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'TATUAJE - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div className="max_width padding_width_chico blanco">
      <center>
        <h11>TATUAJE</h11>
        <br/><br/>
        Los tatuajes han tenido uso simbólico desde culturas antiguas en diferentes sociedades. Los usaban los grupos prehispánicos aztecas, mayas y amazónicos, los cuales acostumbraban también perforarse y hacerse incrustaciones; además, en oriente los maorís y otros grupos de las islas del Pacífico.
      </center>
      <br/>
      {
        window._my_galeria.filter(p => p.seccion==='tatuaje').length>0 ?
          window._my_galeria.filter(p => p.seccion==='tatuaje').map((item, i) => 
            <a href={item.foto} target="new" key={i} className="my_galeria" data-aos={((i+1)%2)===0 ? "fade-up" : "fade-down"}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </a>
          )
        :
          null
      }
      <div id="cl"><br/></div>
    </div>
  );
}

export default Tatuaje;

import { useEffect } from 'react';
import splash from './ultilidades/splash'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();


function newObject(obj) {
  // Convertir a cadena
  let newObj = JSON.stringify(obj);
  // Devolver como objeto
  return JSON.parse(newObj);
  // Podría ser una sola línea:
  // return JSON.parse(JSON.stringify(obj));
}

const arreglo = newObject(window._my_galeria)
arreglo.sort(function() { return Math.random() - 0.5 });

function Galeria() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'TATUAJE - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []); 

  return (
    <div className="max_width padding_width_chico blanco">
      <center>
        <h11>GALERÍA</h11>
        <br/><br/>
        <b>Una imagen vale más que mil palabras y aquí te ponemos más de una.</b>
        <br/>
        En nuestra galería de trabajos encontrarás una selección de nuestros trabajos.
      </center>
      <br/>
      {
        arreglo.length>0 ?
          arreglo.map((item, i) => 
            <a href={item.foto} target="new" key={i} className="my_galeria" data-aos={((i+1)%2)===0 ? "fade-up" : "fade-down"}>
              <LazyLoadImage
                alt={item.titulo}
                title={item.titulo}
                effect="blur"
                wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: {transitionDelay: "1s"},
                }}
                src={item.foto} />
              {/*<img src={item.foto} title={item.titulo} alt={item.titulo}/>*/}
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </a>
          )
        :
          null
      }
      <div id="cl"><br/></div>
    </div>
  );
}

export default Galeria;

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import splash from './ultilidades/splash'
import Album from './Album'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Inicio() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'LOS TRES CALAVERA, ESTUDIO DE TATUAJE, PIERCING Y ESTILISTA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div>
      <div className="max_width padding_width_chico">
        <Album />
        <div className="flexy">
          <img src="img/dragon.svg" alt="Dragón"/>
          <Link to="contacto" className="agenda">
            Agenda tu cita
          </Link>
          <img src="img/dragon.svg" alt="Dragón" className="rotado"/>
        </div>
        <div className="flex_agenda">
          <div className="de_agenda">
            <img src="img/exagono.svg" alt="Paso"/>
            1.- Cotiza
          </div>
          <div className="de_agenda">
            <img src="img/exagono.svg" alt="Paso"/>
            2.- Enviá anticipo
          </div>
          <div className="de_agenda">
            <img src="img/exagono.svg" alt="Paso"/>
            3.- Agenda tu cita
          </div>
        </div>
        <div className="flex_local">
          <img src="img/l_1.jpg" title="Local" alt="Local" data-aos="fade-up"/>
          <img src="img/l_2.jpg" title="Local" alt="Local" data-aos="fade-down"/>
          <img src="img/l_3.jpg" title="Local" alt="Local" data-aos="fade-up"/>
          <img src="img/l_4.jpg" title="Local" alt="Local" data-aos="fade-down"/>
        </div>
        <div className="calavera_ubicacion">
          <img src="img/ubicacion.png" title="Ubicación" alt="Ubicación" className="cal_ubi"/>
          <div className="titulo_ubicacion">Ubicación</div>
          {window.domicilio}
          <br/>
          HORARIO - {window.horario}
          <br/><br/>
          <a href={`tel:${window.telefonoDosM}`} target="new">TEL: {window.telefono}</a>
          <br/>
          <a href={window.whats} target="new">WHATSAPP: {window.telefonoW}</a>
        </div>
        <iframe src={window.mapa} data-aos="fade-down" className="mapa" loading="lazy" title="Ubicación" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
}

export default Inicio;

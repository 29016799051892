import { useEffect } from 'react';
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Estilista() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'ESTILISTA - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div className="max_width padding_width_chico blanco">
      <center>
        <h11>ESTILISTA</h11>
        <br/>
        <b>Tona Raudry - Hair Stylist | Peluquero</b>
        <br/>
        Todos somos arte en las manos de la persona correcta.
      </center>
      <br/>
      {
        window._my_galeria.filter(p => p.seccion==='estilista').length>0 ?
          window._my_galeria.filter(p => p.seccion==='estilista').map((item, i) => 
            <a href={item.foto} target="new" key={i} className="my_galeria" data-aos={((i+1)%2)===0 ? "fade-up" : "fade-down"}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </a>
          )
        :
          null
      }
      <div id="cl"><br/></div>
    </div>
  );
}

export default Estilista;

import { useEffect, useState } from 'react';
import Axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import splash from './ultilidades/splash'
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function Contacto() {
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'CONTACTO - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div className="max_width padding_width_chico">
      <div className="blanco">
        <center><h11>CONTACTO</h11></center>
        <br/>
      </div>
      <div className="flex_contacto">
        <div className="info_contacto" data-aos="fade-right">
          <h11>Redes sociales:</h11>
          <div className="iconos_contacto">
            <div className="flex_redes startt">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre} className="hvr-push"/></a>
                )
              }
            </div>
          </div>
          <h11>Información de contacto:</h11>
          <div className="iconos_contacto">
            <a href={`tel:${window.telefonoM}`} target="new" className="flex_tel2 alinear">
              <img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/>
              {window.telefono}
            </a>
            <a href={`mailto:${window.correo}`} target="new" className="flex_tel2 alinear">
              <img src="img/con_3.svg" title="Correo electrónico" alt="Correo electrónico"/>
              {window.correo}
            </a>
            <div className="flex_tel2 alinear">
              <img src="img/con_4.svg" title="Horario" alt="Horario"/>
              {window.horario}
            </div>
            <a href={window.ubicacion} target="new" className="flex_tel2 alinear">
              <img src="img/con_2.svg" title="Ubicación" alt="Ubicación"/>
              {window.domicilio}
            </a>
          </div>
          <iframe src={window.mapa} className="mapa mapa_contacto" loading="lazy" title="Ubicación" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <form data-aos="fade-left">
          <h11>Envianos un mensaje:</h11>
          <br/><br/>
          <div>
            <label>*Nombre:</label>
            <input type="text" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" value={nombre} required/>
          </div>
          <div>
            <label>*Correo electrónico:</label>
            <input type="text" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo electrónico" value={correo} required/>
          </div>
          <div>
            <label>*Teléfono:</label>
            <input type="text" onChange={(e) => setTelefono(e.target.value)} placeholder="10 digitos" value={telefono} required/>
          </div>
          <div>
            <label>*Mensaje:</label>
            <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
          </div>
          <button onClick={(e) => {
            e.preventDefault();
            if(nombre===''){
              alerta('error','Ingresa tu nombre completo');
            }else if(telefono===''){
              alerta('error','Ingresa tu teléfono o número celular');
            }else if(correo===''){
              alerta('error','Ingresa tu correo electrónico');
            }else if(window.validarMail(correo)===false){
              alerta('error','Ingrese un correo electrónico valido');
            }else if(mensaje===''){
              alerta('error','Escribe un mensaje');
            }else{
              window.H5_loading.show();
              var params = new URLSearchParams();
              params.append('nombre', nombre);
              params.append('correo', correo);
              params.append('mensaje', mensaje);
              params.append('telefono', telefono);
              
              Axios.post(`${window.envia}`, params)
                .then(response => {
                  if(response.data.elError===1){
                    alerta('success',response.data.mensaje)
                    setNombre("");
                    setCorreo("");
                    setTelefono("");
                    setMensaje("");
                  }else{
                    alerta('error',response.data.mensaje)
                  }
                })
                .catch(error => {
                  //window.alerta('error','LOLERO'+error);
                  console.log(error)
                })
                .then(function() {
                  window.H5_loading.hide();
                })
            }
          }}>Enviar mensaje</button>
          <div id="cl"/>
        </form>
      </div>
    </div>
  );
}

export default Contacto;

import { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
//import splash from './splash'

function Album() {
  const responsive = {
    0: { items: 1 },
    350: { items: 2 },
    550: { items: 3 },
  };

  const carousel = useRef(null);

  return (
    <div className="album">
      <div className="tamano_album">
        <AliceCarousel
          responsive={responsive}
          autoPlayInterval={3000}
          //autoPlayDirection="rtl"
          autoPlay={true}
          fadeOutAnimation={true}
          mouseTrackingEnabtutoled={false}
          playButtonEnabled={false}
          disableAutoPlayOnAction={false}
          dotsDisabled={true}
          buttonsDisabled={true}
          ref={carousel}
        >
          {
            window._my_album.map((item, i) => 
              <div key={i}>
                <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              </div>
            )
          }
        </AliceCarousel>
      </div>
      <div className="ref_buttons">
        <button onClick={(e) => carousel?.current?.slidePrev(e)}><img src="img/flecha.svg" alt="Atrás" title="Atrás"/></button>
        <button onClick={(e) => carousel?.current?.slideNext(e)} className="flecha_siguiente"><img src="img/flecha.svg" alt="Siguiente" title="Siguiente"/></button>
      </div>
    </div>
  );
}

export default Album;

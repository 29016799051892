import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';
import { Element } from 'react-scroll';

import Menu from './components/Menu'
import Pie from './components/Pie'

import Inicio from './components/Inicio'
import Historia from './components/Historia'
import Tatuaje from './components/Tatuaje'
import Estilista from './components/Estilista'
import Piercing from './components/Piercing'
import Contacto from './components/Contacto'
import Galeria from './components/Galeria'

function App() {
  const [verMenu, setVerMenu] = useState('no_ver_menu');

  return (
    <Router>
      <Element name="inicio"></Element>
      <div className="icono_menu" id={verMenu==='no_ver_menu' ? 'ver_menu' : 'no_ver_menu'} onClick={() => setVerMenu('ver_menu')}><img src="img/menu.svg" title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={verMenu} onClick={() => setVerMenu('no_ver_menu')}></div>
      <div className="menu_abre" id={verMenu}>
        <Menu setVerMenu={setVerMenu}/>
      </div>
      <div className="max_width_menu">
        <Link to="inicio"><img src="img/logo.png" title="LOS TRES CALAVERA" alt="LOS TRES CALAVERA" className="logo_calavera hvr-push"/></Link>
      </div>
      <Link to="galeria" className="boton_galeria">
        <img src="img/boton.svg" title="Galería" alt="Galería" className="hvr-push"/>
      </Link>
      <StickyContainer>
        <Sticky>
          {({
            style,
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight
          }) => (
            <header style={style} className="de_menu_calavera" id={window.screen.width>690 ? null : 'no_ver_menu'}>
              <Menu />
            </header>
          )}
        </Sticky>
        <Switch>
          <Route exact path="/" component={Inicio} />
          <Route path={window.path+"/inicio"} component={Inicio} />
          <Route path={window.path+"/historia"} component={Historia} />
          <Route path={window.path+"/tatuaje"} component={Tatuaje} />
          <Route path={window.path+"/estilista"} component={Estilista} />
          <Route path={window.path+"/piercing"} component={Piercing} />
          <Route path={window.path+"/contacto"} component={Contacto} />
          <Route path={window.path+"/galeria"} component={Galeria} />
          <Route component={Inicio} />
        </Switch>
      </StickyContainer>
      <Pie />
    </Router>
  );
}

export default App;
import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link as Ir } from 'react-scroll';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';


function Pie() {
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");

  return (
    <div>
      <div className="pie">
        <div className="max_width padding_pie linea_pie">
          <div className="mi_pie alinear">
            <center><Ir to="inicio" className="subir">➤</Ir></center>
            {window.nombre} © {window.ano}
            <div className="flex_redes centerr">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre} className="hvr-push"/></a>
                )
              }
            </div>
            <div className="flex_end alinear">
              <a href={`tel:${window.telefonoM}`} target="new" className="flex_tel alinear">
                <img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/>
                {window.telefono}
              </a>
              <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                <img src="img/con_3.svg" title="Correo electrónico" alt="Correo electrónico"/>
                {window.correo}
              </a>
            </div>
            <a href="https://www.adsvega.com/" target="new" className="vega grises">
              <img src="img/vega.svg?v=0" title="ecommerce web design" alt="ecommerce web design"/>
            </a>
            <div className="para_aviso">
              <div onClick={() => {
                setModalServicios(true)
                setServicio("preguntas")
              }}>Preguntas frecuentes</div>
                
              <div onClick={() => {
                setModalServicios(true)
                setServicio("aviso")
              }}>Aviso de privacidad</div>
            </div>
          </div> 
        </div>
      </div>
      <a href={window.whats} target="new" className="whats">
        <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
      </a>
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j">
                  Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que <b>{window.nombre}</b> con domicilio en <b>{window.domicilio}</b> es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
                  <br/><br/>
                  Su información personal será utilizada para las siguientes finalidades: proveer los servicios y productos que ha solicitado; notificarle sobre nuevos servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros productos y servicios a efecto de mejorar la calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con usted.
                  <br/><br/>
                  Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
                  <ul className="lista_avi">
                    <li>Nombre completo</li>
                    <li>Teléfono fijo y/o celular</li>
                    <li>Correo electrónico</li>
                  </ul>
                </div>
              </Modal.Body>
            </>
          : servicio==='preguntas' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Preguntas frecuentes</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          {item.respuesta}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          :
            null
        }
      </Modal>
    </div>
  );
}

export default Pie;

import { useEffect } from 'react';
import splash from './ultilidades/splash'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Piercing() {
  let initialized = false
  useEffect(() => {
    if(!initialized){
      document.title = 'PIERCING - LOS TRES CALAVERA';
      initialized = true //eslint-disable-line
      splash()
    }
  }, []);

  return (
    <div className="max_width padding_width_chico blanco">
      <center>
        <h11>Piercing</h11>
        <br/><br/>
        Los habitantes del antiguo Egipto usaban diferentes tipos de piercing en la oreja, sobre todo como símbolo de poder dentro de la jerarquía social establecida. Además, también se perforaban los labios y la nariz como parte de sus prácticas religiosas y culturales, más allá de posibles razones estéticas. Los mayas utilizaban técnicas de perforación en el labio inferior, en la lengua y en las orejas para adornar su cuerpo con hermosos pendientes y así denotar su estatus.
        <br/><br/>
        En la actualidad, los piercings son reconocidos socialmente como auténticas piezas de joyería, capaces de transmitir elegancia, innovación, delicadeza e incluso sofisticación.
      </center>
      <br/>
      {
        window._my_galeria.filter(p => p.seccion==='piercing').length>0 ?
          window._my_galeria.filter(p => p.seccion==='piercing').map((item, i) => 
            <a href={item.foto} target="new" key={i} className="my_galeria" data-aos={((i+1)%2)===0 ? "fade-up" : "fade-down"}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </a>
          )
        :
          null
      }
      <div id="cl"><br/></div>
    </div>
  );
}

export default Piercing;
